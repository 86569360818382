<style lang="less" scoped>
.item-block {
  min-width: 300px;
  width: 25%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  .wrap {
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #FFF;
  }
  &.end, &.unable {
    .wrap {
      background-color: #dfdfdf;
    }
  }
}
.item-block {
  .bar {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 20px;
    }
    .iconfont {
      cursor: pointer;
      font-size: 25px;
      font-weight: 800;
    }
  }
  .info {
    margin: 10px 0 20px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .action {
    display: flex;
    justify-content: space-around;
  }
}
</style>

<style lang="less" scoped>
.action {
  display: flex;
  flex-wrap: wrap;
  .btn {
    background-color: #F8F8F8;
    font-size: 12px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 2px 5px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
    &:hover, &:active {
      background-color: #ddd;
    }
    & + .btn {
      margin-left: 5px;
    }
  }
}
</style>

<template>
  <div class="item-block" :class="{ [data.status]: true }">
    <div class="wrap">
      <div class="bar">
        <span class="status">{{statusText}}</span>
      </div>
      <div class="info">
        <div class="time">{{data.year}}年</div>
        <div v-if="!myIndex">{{data.isOverDetailCount}} / {{data.detailCount}}</div>
        <div v-else>{{isOver ? 1 : 0}} / 1</div>
      </div>
      <div class="action">
        <template v-for="action in btns">
          <div class="btn" v-if="action.show" :key="action.key" @click="$emit('action', { action, data })">{{action.label}}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => ({}) }
  },
  computed: {
    isOver () {
      if (this.data.myDetail) {
        const mycheck = (this.data.myDetail.checkDatas || []).find(v => v.type === 'my')
        return this.data.myDetail.status === 'submit' && mycheck && mycheck.status === 'submit'
      } else {
        return false
      }
    },
    myIndex () {
      return this.$authFunProxy['evaluation.index-myindex']
    },
    statusText () {
      switch (this.data.status) {
        case 'draw':
          return '创建中'
        case 'doing':
          return '登记中'
        case 'end':
          return '已结束'
        default:
          return '其他'
      }
    },
    btns () {
      const fun = this.$authFunProxy
      if (this.data.status === 'draw') {
        return [
          { label: '修改', key: 'update', show: fun['evaluation.index-update'] },
          { label: '删除', key: 'del', show: fun['evaluation.index-del'] },
          { label: '开始登记', key: 'doing', show: fun['evaluation.index-status'] }
        ]
      } else if (this.data.status === 'doing') {
        return [
          { label: '查看', key: 'details', show: fun['evaluation.index-getDetails'] },
          { label: '回退创建', key: 'draw', show: fun['evaluation.index-status'] },
          { label: '结束', key: 'end', show: fun['evaluation.index-status'] }
        ]
      } else if (this.data.status === 'end') {
        return [
          { label: '查看', key: 'details', show: fun['evaluation.index-getDetails'] },
          { label: '回退登记', key: 'doing', show: fun['evaluation.index-status'] }
        ]
      } else {
        return []
      }
    }
  }
}
</script>
