<style lang="less" scoped>
  .evaluation-page {
    overflow: auto;
  }
</style>

<style lang="less">
.evaluation-list {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  position: relative;
}
.add-block {
  min-width: 300px;
  width: 25%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  .wrap {
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;

    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  .icon-add {
    font-size: 40px;
    font-weight: 800;
  }
  .text {
    text-align: center;
    font-weight: 800;
    margin-top: 10px;
    font-size: 18px;
  }
}
</style>

<template>
  <div class="evaluation-page">
    <div class="evaluation-list">
      <div class="add-block" v-if="$authFunProxy['evaluation.index-add']">
        <div class="wrap" @click="add">
          <i class="iconfont icon-add"></i>
          <div class="text">创建考核登记</div>
        </div>
      </div>
      <evaluation-item
        @action="itemAction"
        v-for="item in dataList"
        :data="item"
        :key="item.id"
      />
    </div>
    <fm-modal :mask-closable="false" :width="1200" v-model="status.form" :title="chooseData && chooseData.id ? '修改年度考核' : '新增年度考核'">
      <evaluation-form ref="form" :data="chooseData" />
      <div slot="footer" class="modal-footer-btns">
        <fm-btn v-loadingx="loading.submit" @click="submit">{{chooseData && chooseData.id ? '保存' : '新增'}}</fm-btn>
        <fm-btn @click="status.form = false">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import EvaluationForm from './components/form'
import EvaluationItem from './components/item'

import { appraisalYearRequest } from '@/api'

export default {
  name: 'BudgetIndex',
  components: { EvaluationForm, EvaluationItem },
  props: {
    type: String
  },
  data () {
    return {
      chooseData: null,
      dataList: [],
      loading: {
        submit: false
      },
      status: {
        form: false,
        stages: false
      }
    }
  },
  computed: {
  },
  methods: {
    itemAction ({ action, data }) {
      if (action.key === 'details') {
        this.$router.push({
          name: 'evaluation.details', query: {
            id: data.id
          }
        })
      } else if (action.key === 'update') {
        this.edit(data)
      } else if (action.key === 'del') {
        this.delItem(data)
      } else {
        this.statusSwitch(data, action)
      }
    },
    async statusSwitch (data, action) {
      if (this.$authFunProxy['evaluation.index-status']) {
        const confirm = await this.$dialog.confirm({ title: '提示', content: '确定' + action.label + '?' })
        if (confirm) {
          await appraisalYearRequest.status(data.id, { status: action.key })
          this.$notice.success({ title: action.label + '成功' })
          this.loadData()
        }
      }
    },
    add () {
      this.chooseData = null
      this.status.form = true
    },
    edit (data) {
      this.chooseData = data
      this.status.form = true
    },
    async delItem (data) {
      if (this.$authFunProxy['evaluation.index-del']) {
        const confirm = await this.$dialog.confirm({ title: '提示', content: '确定要删除吗？' })
        if (confirm) {
          await appraisalYearRequest.del(data.id)
          this.loadData()
        }
      }
    },
    async submit () {
      try {
        this.loading.submit = true
        const data = this.$refs.form.getData()
        delete data.orgs
        if (data.id) {
          if (this.$authFunProxy['evaluation.index-update']) {
            await appraisalYearRequest.update(data.id, data)
            this.$notice.success({ title: '修改成功' })
          }
        } else {
          if (this.$authFunProxy['evaluation.index-add']) {
            await appraisalYearRequest.add(data)
            this.$notice.success({ title: '新增成功' })
          }
        }
        this.loading.submit = false
        this.status.form = false
        this.loadData()
      } catch (error) {
        this.loading.submit = false
        this.$notice.error({
          title: '提示',
          desc: error.message
        })
      }
    },
    async loadData () {
      if (this.$authFunProxy['evaluation.index-get']) {
        this.dataList = await appraisalYearRequest.get()
      } else {
        this.$notice.error('无权限')
      }
    }
  },
  mounted () {
    this.$store.dispatch('loadAllWorkerList')
    this.loadData()
  }
}
</script>
